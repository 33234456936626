/* eslint no-undef: 0 */
import {
  exists,
  ResizeHandler,
  isDesktopWidth,
  isTabletWidth,
  isMobileWidth,
  inViewportHeight,
  swapHeaderImages,
  addMobileOverflowFix,
  removeMobileOverflowFix,
  isHomepageExtendedHeroException
} from '../base/dom-utils.js';
import {eagerLoadImage} from '../base/eager-load.js';
import {lazyLoadImage} from '../base/lazy-load.js';
import {
  checkModalHeightAgainstWindow,
  getModalSize
} from '../base/lightbox.js';
import {
  getHeaderHeight,
  EventHandler,
  getPromoHeight,
  getBookingBarHeight,
  getHeroHeight,
  CookieHandler,
  _isNotNull,
  hasRetailBanner,
  getRetailBannerHeight
} from '../base/utils.js';
import {
  $_BOOKING_BAR_MAIN_,
  $_BOOKING_BAR_MINI_NAV,
  $_HEADER_,
  $_PAGE_,
  $_PAGE_TOP_,
  $_PROMO_BANNER_,
  $_PAGE_HERO_,
  _LOCALE_,
  _SEO_LOCALE_,
  scrollEvents,
  isPublish,
  desktopScrollDistance,
  brand_id,
  requestUrls,
} from '../base/vars.js';
import Handlebars from 'handlebars';

class Header {
  constructor() {
    this.$mainMenu = $('#mainMenuButton');
    this.$magnifyingGlass = $('#magnifyingGlassButton');
    this.$brandLogo = $_HEADER_.find('.navbar__logo-container img');
    this.$rewardsLogo = $_HEADER_.find('.wyndham-rewards-logo');
    this.headerImgs = $_HEADER_.find('img');
    this.$hero = $_PAGE_HERO_;
    this.$promoRibbon = $_PROMO_BANNER_;
    this.count = this.headerImgs.length;
    this.$logoImg = $('.navbar__logo-container img');
    this.$rewardsLogoImg = $('img.wyndham-rewards-logo');
    this.eagerLoadImages();
    this.getHeights();
    this.onHeaderLoad();
    this.initHeaderImages();
    this.loadHeader();

    this.lazyLoadImages();
    this.onShowModal();
    this.onShowDropdown();
    this.hideMagnifyingGlass();
    this.changeLogo();
    this.onMainMenuClick();
    this.onMainMenuButtonClick();
    this.onUserDetailsClick();
    this.onLoginDisplayClick();
    this.onMouseUp();
    this.onMagnifyingGlassClick();
    this.changeSelectedLanguage();
    this.onResizeHandlerEnd();
    this.tollFreeNumberNav();
    if (brand_id !== 'wr') {
      this.retailBanner();
    }
  }
  getHeights() {
    if(!$('#new-nav-book').is(':visible')){
    $_PAGE_.removeClass('scrolled'); // This is needed in order to get the height of the booking bar.
    }
    this.headerHeight = getHeaderHeight();
    this.promoHeight = getPromoHeight();
    this.bookingBarHeight = getBookingBarHeight();
    this.heroHeight = getHeroHeight();
  }
  determinePageScroll(){
    let scrollTop = $(document).scrollTop(),
      isMobile = isMobileWidth(),
      headerHeight = this.headerHeight,
      promoHeight = this.promoHeight,
      heroHeight = this.heroHeight,
      isScrolled = false,
      mobileScrollBreakpoint = headerHeight;

    if(isMobile){
      if(heroHeight > 0){
        mobileScrollBreakpoint = heroHeight;
      } else if(promoHeight > 0) {
        mobileScrollBreakpoint = headerHeight + promoHeight;
      }
      if (exists('.retail-banner-container')) {
        mobileScrollBreakpoint = mobileScrollBreakpoint + headerHeight + $('.retail-banner-container').height();
      }
    }


    if((isMobile && (scrollTop > mobileScrollBreakpoint)) || (!isMobile && (window.pageYOffset > desktopScrollDistance))){
      isScrolled = true;
    }

    if(isScrolled && isMobile){
      $_PAGE_.addClass('scrolled');
    } else {
      if(!$('#new-nav-book').is(':visible')) {
      $_PAGE_.removeClass('scrolled');
      }
    }
  }
  initHeaderImages() {
    this.determinePageScroll();

    if (!(isMobileWidth()) && !isHomepageExtendedHeroException() && !$('#pageHeader').is('.old-navigation-hide')) {
      if(!$_PAGE_.hasClass('scrolled')){
        swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'white');
      } else {
        swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'color');
      }
      if ($_PAGE_.is('.homepage, .uu-overview')) {
        $_HEADER_.on('mouseenter', (e) => {
          $_PAGE_.addClass('scrolled'); // used only for styling the header
          swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'color');
        }).on('mouseleave', (e) => {
          this.determinePageScroll();
          if($_PAGE_.hasClass('scrolled')){
            swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'color');
          } else {
            swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'white');
          }
        });
      }
    } else if (isHomepageExtendedHeroException() || $('#pageHeader').is('.old-navigation-hide')) {
      swapHeaderImages(this.$logoImg, this.$rewardsLogoImg, 'color');
    }
  }
  loadHeader() {
    if (this.count > 0) {
      this.headerImgs.each((i, el) => {
        if((el.complete || el.naturalWidth > 0) && i === 0){
          EventHandler.send(EventHandler.header.load);
        } else {
          $(el).one('load', () => {
            if (i === 0) {
              EventHandler.send(EventHandler.header.load);
            }
          });
        }
      });
    } else {
      if (!(getHeaderHeight())) {
        let INT = setInterval(() => {
          if (getHeaderHeight()) {
            EventHandler.send(EventHandler.header.load);

            clearInterval(INT);
          }
        }, 111);
      } else {
        EventHandler.send(EventHandler.header.load);
      }
    }
  }
  lazyLoadImages() {
    $('.lazy-load,.has-bg-img').each((index, el) => {
      if (!$(el).hasClass('lazy-load-pageload')) {
        /** header.js **/
        lazyLoadImage($(el));
        $(el).addClass('lazy-load-pageload');
      }
    });
  }
  eagerLoadImages() {
    $('.eager-load').each((index, el) => {
      if (!$(el).hasClass('eager-load-pageload')) {
        /** header.js **/
        eagerLoadImage($(el));
        $(el).addClass('eager-load-pageload');
      }
    });
  }
  positionPromoBanner() {
    this.$promoRibbon.css({
      top: getHeaderHeight()
    });
    $('.hero-drawer-container').css({
      top: getPromoHeight()
    });
  }
  onHeaderLoad() {
    EventHandler.on(EventHandler.header.load, () => {
      this.positionPromoBanner();
      this.determinePageScroll();
      this.setMobileBookingBarPosition();
      this.setPageTopHeight();
    });
  }
  setPageTopHeight() {
    if (exists($_PAGE_TOP_)) {
      $_PAGE_TOP_.css({
        height: getHeaderHeight()
      });
    }
    if (exists('.page-hero.component') && exists('#heroPromoRibbon') && isMobileWidth()) {
      $('div[role=main].container-fluid').css('marginTop', 0);
      if($('#pageHeader').is('.old-navigation-hide')){
        $('.homepage-hero-component').css('marginTop', $('#pageHeadernew .top-nav').height());
      } else {
        $('.homepage-hero-component').css('marginTop', $('#pageHeader').height());
      }
    }
  }
  positionBookingBarAboveBody(){
    $_BOOKING_BAR_MAIN_[0].style.top = '-150%';
  }
  resetMobileBookingBarStyles(){
    $_BOOKING_BAR_MAIN_[0].style.transition = '';
    $_BOOKING_BAR_MAIN_[0].style.top = '';
  }
  setMobileBookingBarPosition(){
    if(isMobileWidth()){
      if(!$_BOOKING_BAR_MAIN_.hasClass('open') && !$("input.destination").is(":focus")){
        if(!$_PAGE_.hasClass('scrolled')){
          // Top of page
          if (hasRetailBanner() && $_PAGE_.is('.homepage')) {
            $_BOOKING_BAR_MAIN_.css({
              top: 0,
              transition: 'none',
              marginTop: getHeaderHeight() + this.$promoRibbon.height()
            });
          } else {
            $_BOOKING_BAR_MAIN_.css({
              top:$_PAGE_.hasClass('homepage') ? getHeaderHeight() + this.$promoRibbon.height() : '-150%',
              transition: 'none'
            });
          }
        } else {
          // Scrolled down page
          $_BOOKING_BAR_MAIN_[0].style.transition = '';
          this.positionBookingBarAboveBody();
        }
      }
    }
  }
  onShowModal() {
    $('body')
      .on('show.bs.modal', checkModalHeightAgainstWindow)
      .on('show.bs.modal', getModalSize);
  }
  onShowDropdown() {
    $_HEADER_
      .on('show.bs.dropdown', () => {
        $_HEADER_.attr('data-z-index', $_HEADER_.css('z-index')).css({
          zIndex: $_BOOKING_BAR_MAIN_.css('z-index') + 1
        });

        $('body').one(scrollEvents, (e) => {
          if (!$(e.currentTarget).hasClass('mobile-overflow-hidden')) {
            $('.dropdown.open').click();
          }
        });
      })
      .on('hidden.bs.dropdown', () => {
        $_HEADER_.css({
          zIndex: $_HEADER_.data('z-index')
        });
      });
  }
  hideMagnifyingGlass() {
    if ($('.booking-bar-form').hasClass('display-none')) {
      this.$magnifyingGlass.addClass('display-none');
    }
  }
  changeLogo() {
    if (!this.isTransparentNav()) {
      this.$brandLogo.attr('src', this.$brandLogo.data('color'));
      this.$rewardsLogo.attr('src', this.$rewardsLogo.data('color'));
    }
  }
  //If it is homepage or upscale propertyOverview page in desktop resolution
  isTransparentNav() {
    return ($('.uu-property.uu-overview #pageHeader').length > 0 && isDesktopWidth()) || $_PAGE_.is('.homepage');
  }
  onMainMenuClick() {
    this.$mainMenu.click((e) => {
      if (!(this.$magnifyingGlass.hasClass('display-none'))) {
        if (this.$magnifyingGlass.attr('aria-expanded') == 'true') {
          this.$magnifyingGlass.click();
        }
      }
      if ($(e.currentTarget).attr('aria-expanded') == 'false') {
        $_BOOKING_BAR_MINI_NAV.css({
          zIndex: 'auto'
        });
        addMobileOverflowFix();
      } else {
        // Remove Z-index on closing
        $_BOOKING_BAR_MINI_NAV.removeAttr('style');
        removeMobileOverflowFix();
      }
    });
  }
  onMainMenuButtonClick() {
    $('#mainMenuButton').on('click', (e) => {
      if ($('#headerLinkContainer').height() + 90 > $(window).height()) {
        $('#headerLinkContainer').css('max-height', ($(window).height() - 90).toString() + 'px');
        $('#headerLinkContainer').css('overflow-y', 'scroll');
      }
    });
  }
  onUserDetailsClick() {
    $('.wyndham-rewards-user-details').on('click', (e) => {
      $('.wyndham-rewards-logged-in').toggleClass('login-show');
      $('.wyndham-rewards-user-details .caret').toggleClass('caret-rotate');
      $('.wyndham-rewards-logged-in').css('minWidth', $('.wyndham-rewards-user-details').css('width')
        .toString());
      $('#pageHeader').toggleClass('header-overlap');

    });
  }
  onLoginDisplayClick() {
    $('.login-display').on('click', (e) => {
      $('.error-msg-container').hide();
      $('.login-modal-content').removeClass('login-required');
    });
  }
  onMouseUp() {
    $(document).mouseup((e) => {
      if (isDesktopWidth() && $('.wyndham-rewards-logged-in').hasClass('login-show') &&
        !$('.wyndham-rewards-logged-in').is(e.target) && $('.wyndham-rewards-logged-in').has(e.target).length === 0 &&
        !$('.wyndham-rewards-user-details').is(e.target) && $('.wyndham-rewards-user-details').has(e.target).length === 0
      ) {
        $('.wyndham-rewards-logged-in').toggleClass('login-show');
        $('.wyndham-rewards-user-details .caret').toggleClass('caret-rotate');
        $('#pageHeader').toggleClass('header-overlap');
      }
    });
  }

  openMagnifyGlass() {
    let mainMenuExpanded = this.$mainMenu.attr('aria-expanded') == 'true';

    if (mainMenuExpanded) {
      this.$mainMenu.click();
    }
    this.resetMobileBookingBarStyles();
    $_BOOKING_BAR_MAIN_.addClass('open');

    if (isTabletWidth()) {
      if ($_PAGE_.is('.homepage.scrolled') || $_PAGE_.is('.search-results-page.collapsed') || $_PAGE_.is('.property-page.collapsed') || $_PAGE_.is('.rooms-rates-page.collapsed') || $_PAGE_.is('.packages-page') || $_PAGE_.is('.packages-page.collapsed')) {
        const retailBannerVisibleHeight = (exists($('.retail-banner-container')) ? inViewportHeight($('.retail-banner-container')) : 0);
        $_BOOKING_BAR_MAIN_.css({
          top: getHeaderHeight() + retailBannerVisibleHeight,
          opacity: 1
        });
      }
    }

    if (isMobileWidth()) {
      let sab = $('#smartbanner'), alc = $('.alert-component');
      if (sab.length > 0 || alc.length > 0) {
        if (sab.length > 0) {
          sab.hide();
        }
        if (alc.length > 0) {
          alc.hide();
        }
      }
      $('.outer-wrapper.page').css('margin-top', 0);
      if (exists('.retail-banner-container') && !exists('#smartbanner')) {
        $('#pageHeader').css({
          top: 0,
          marginTop: 0
        });
        if ($_PAGE_.is('.homepage')) {
          $('#bookingBar__main').css({
            top: $('#pageHeader').outerHeight(),
            marginTop: 0
          });
        }
      } else {
        $('#pageHeader').css('top', 0);
        $('#bookingBar__main').css({
          marginTop: $_PAGE_.hasClass('homepage') ? 0 : $_BOOKING_BAR_MAIN_.css('marginTop')
        });
      }
      $('body').addClass('mobile-overflow-hidden');
      $('.rtp-awareness').addClass('show-hide-rtp');

      if($_PAGE_.is('.rooms-rates-page') && $('#pageHeadernew').is(':visible')) {
        $('#bookingBar__main').css({
          marginTop: 20
        });
      }

    }
    this.$magnifyingGlass.attr('aria-expanded', true);
  }

  closeMagnifyGlass() {
    $_BOOKING_BAR_MAIN_.removeClass('open');

    $('body').removeClass('mobile-overflow-hidden');
    $('.rtp-awareness').removeClass('show-hide-rtp');
    this.positionBookingBarAboveBody();
    this.$magnifyingGlass.attr('aria-expanded', false);

    if(isMobileWidth()){
      let sab = $('#smartbanner'),alc = $('.alert-component'),alch = 0,sabh = 0;
      if (!hasRetailBanner()) {
        if (sab.length > 0 || alc.length > 0 ) {
          if(sab.length > 0) {
            sab.show();
            sabh = $('#smartbanner').outerHeight();
          }
          if(alc.length > 0 ) {
            alc.show();
            alch = $('.alert-component').outerHeight();
          }
        }
        $('.outer-wrapper.page').css('margin-top', (sabh + alch) + 'px');
        $('#pageHeader').css('top', (sabh + alch) + 'px');
        if($('.uu-property.uu-overview').length){
          $('#pageHeader').css('top', 0);
        }
      } else {
        if (sab.length > 0 || alc.length > 0) {
          if (sab.length > 0) {
            sab.show();
          }
          if (alc.length > 0) {
            alc.show();
          }
        }

        if ($_PAGE_.is('.uu-property,.uu-overview')) {
          $('div[role=main].retail-banner-mt').css('marginTop', 0);
          $('#pageHeader').css({top: 0, marginTop: inViewportHeight($('.retail-banner-component'))});
          return;
        }

        if ($_PAGE_.is('.search-results-page, .property-page, .rooms-rates-page')) {
          $('div[role=main].retail-banner-mt').css('marginTop', 0);
          $('.page').css('marginTop', $('.retail-banner-component').outerHeight());
          $('#pageHeader').css({top: 0, marginTop: inViewportHeight($('.retail-banner-component'))});
          return;
        }

        if ($_PAGE_.is('.homepage')) {
          $('.page').css('marginTop', 0);
          $('#pageHeader').css('top', 0);
          return;
        }
      }
    }
  }
  onMagnifyingGlassClick() {
    this.$magnifyingGlass.click((e) => {
      $('.booking-input-container.destination-container').find('.destination-text').remove();
      $('[name="destination"]').removeClass('hidden');
      $('.booking-bar-main').find('.search-btn').text($('.booking-bar-main').find('.search-btn').attr('original-label'));
      if (!(this.$magnifyingGlass.hasClass('display-none'))) {
        if (this.$magnifyingGlass.attr('aria-expanded') == 'false' || (parseFloat($('.booking-bar').css('top')) < 0) && !$_PAGE_.is('.homepage')) {
          this.openMagnifyGlass();
        } else {
          this.closeMagnifyGlass();
        }
      } else {
        return false;
      }
    });
  }
  changeSelectedLanguage() {
    let selectedLang = $('.language-selector li[data-locale=' + _LOCALE_ + ']');
    if (!exists(selectedLang) && _SEO_LOCALE_.length > 0) {
      selectedLang = $('.language-selector li[data-locale=' + _SEO_LOCALE_ + ']');
    }
    selectedLang.find('a').addClass('selected')
      .html(selectedLang.text());
  }
  onResizeHandlerEnd() {
    if(isPublish) {
      ResizeHandler.addResizeEndFn(() => {
        this.getHeights();
        this.setPageTopHeight();
        $('img,.has-bg-img').each((index, el) => {
          if (!$(el).hasClass('lazy-load-pageload')) {
            /** header.js **/
            lazyLoadImage($(el));
          }
        });
        this.determinePageScroll();
      }, 'both');
    }
  }
  retailBanner() {
    new RetailBanner();
  }

  tollFreeNumberNav() {
    let countryCode = CookieHandler.readCookie('country_code');
    let tollFreeNumbersByBrand;
    this.fetchCountryListMapData().then((tollFreeNumberMap) => {
      tollFreeNumbersByBrand = tollFreeNumberMap;
      let navEle = $('.nav-toll-free-number');
      if(countryCode != null && tollFreeNumbersByBrand[countryCode.toLowerCase()]) {
        navEle.find('a').attr('href','tel:' + tollFreeNumbersByBrand[countryCode.toLowerCase()]);
        navEle.find('a').html(tollFreeNumbersByBrand[countryCode.toLowerCase()]);
        navEle.removeClass('hidden');
      }
    }, () => {});
  }

  parseCountryCodeMapList(brandMapJcr) {
    let countryListMap = {};

    for (const property in brandMapJcr) {
      if (property.indexOf('item') >= 0) {
        let countryListObj = brandMapJcr[property];
        if (_isNotNull(countryListObj['jcr:title']) &&
          _isNotNull(countryListObj['value'])) {
          let countryNameProcessed = this.getCountryNameProcessed(countryListObj['jcr:title']);
          countryListMap[countryNameProcessed] = countryListObj['value'];
        }
      }
    }

    return Object.keys(countryListMap).length > 0 ? countryListMap : false;
  }

  getCountryNameProcessed(amenityNameEn) {
    return $.trim(amenityNameEn).toLowerCase().replace(/\s+/g, '_');
  }

  fetchCountryListMapData() {
    let countryListMap = {},
      deferred = $.Deferred(),
      brandId = brand_id;

    if(brandId === 'wr' && CookieHandler.sessionExists()) {
      let cookieSession = CookieHandler.getSession();
      brandId = 'wr-' + (cookieSession.level ? cookieSession.level.toLowerCase() : '');
    }

    this.fetchBrandListMapData().then((brandListMap) => {
      let brandList = brandListMap;
      if(brandList[brandId]) {
        if (requestUrls['tollFreeNumberMap']) {
          $.ajax({
            url: requestUrls['tollFreeNumberMap'] + brandId + ".-1.json"
          }).then((response) => {
            if (response.list && response.list.hasOwnProperty('item')) {
              countryListMap = this.parseCountryCodeMapList(response.list);
              if (Object.keys(countryListMap).length) {
                deferred.resolve(countryListMap);
              } else {
                deferred.reject();
              }
            } else {
              deferred.reject();
            }
          }, () => {
            deferred.reject();
          });
        } else {
          deferred.reject();
        }
      }
    },);

    return deferred.promise();
  }

  fetchBrandListMapData() {
    let brandListMap = {},
      deferred = $.Deferred();

    $.ajax({
      url: requestUrls['tollFreeNumberMap'] + "brands/brand-list.-1.json"
    }).then((response) => {
      if (response.list && response.list.hasOwnProperty('item')) {
        brandListMap = this.parseCountryCodeMapList(response.list);
        if (Object.keys(brandListMap).length) {
          deferred.resolve(brandListMap);
        } else {
          deferred.reject();
        }
      } else {
        deferred.reject();
      }
    }, () => {
      deferred.reject();
    });
    return deferred.promise();
  }
}

class RetailBanner {
  constructor() {
    if ($("#retail-banner-carousel").length > 0) {
      let preloadedSlides = localStorage.getItem('wyndham-retail-banner-slides');
      if (preloadedSlides) {
        var slides  = JSON.parse(preloadedSlides);
        localStorage.removeItem('wyndham-retail-banner-slides');
        this.loadSlides(slides, false);
      } else {
        var endpoint = $(".retail-banner-container").data("slidesEndpoint");
        var currentBrand = $(".retail-banner-container").data("currentBrand");
        $.ajax({
          url: endpoint.replace('.json', `.${currentBrand}.json`),
          type: 'GET',
          success: (results, status) => {
            if (status == "success") {
              var filteredSlides = results.slides.filter((slide) => {
                var toFilter = false;
                var today = new Date();
                if (slide.startDate && slide.endDate) {
                  var startDate = new Date(slide.startDate);
                  var endDate = new Date(slide.endDate);
                  if (today > startDate && today < endDate) {
                    toFilter = true;
                  }
                }
                return toFilter;
              });
              this.loadSlides(filteredSlides, true);
            }
          },
          error: (response) => {
            console.error("error on loading retail banner slides");
          }
        });
      }
    } else {
      this.removeRetailBannerPositioningClasses();
    }
  }

  renderSlidesAndInitialize(slides, addTopHeader) {
    var source = $("#retail-banner-carousel").html();
    var template = Handlebars.compile(source);
    var html = template(slides);
    $(".retail-banner-container").html(html);
    if (slides.length == 1) {
      $('.retail-banner-arrow').css('display', 'none');
    }
    new Swiper(".retailbanner-carousel .swiper", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
    $(".retail-banner-text.has-link").click(ev => {
      if (ev.target.nodeName === 'A' && ev.target.parentElement.parentElement.classList.contains('site-wide-alert')) return; // skip if clicking RTE link
      let openNewTab = $(ev.currentTarget).data('linkOpenNewTab');
      let link = $(ev.currentTarget).data('link');
      if (openNewTab) {
        window.open(link, "_blank");
        return;
      }
      window.location.href = link;
    });

    // smart banner ui changes
    if (($_PAGE_.is('.homepage') || (brand_id == 'wr' && $_PAGE_.is('.homepage-template'))) && exists('#smartbanner')) {
      if (isMobileWidth()) {
        $('.page').css('margin-top', 0);
        $(".retail-banner-component").css('margin-top',  ($('#smartbanner').height() + 1) + 'px');
        document.body.addEventListener('sb-closed', () => {
          $('.page').css('margin-top', 0);
          $('.retail-banner-component').css('margin-top', 0);
          $('#pageHeader').css('top', 0);
          $('header.mobile-nav .dropdown').css('marginTop', getRetailBannerHeight());
        });
      } else if (isTabletWidth()) {
        $('#pageHeader').css('top', 0);
      }
    }

    let retailContainerHeight = document.querySelector('.retail-banner-container').offsetHeight;
    // add top margin = retail banner height to elements: pageheader and others
    document.querySelectorAll('.retail-banner-mt').forEach(function(el) {
      if (!el) return;
      el.style.marginTop = retailContainerHeight + 'px';
    });

    if (window.brand_id === 'wr') {
      if ($('.page').is('.search-results-page')) {
        let searchResultEl = document.querySelector('.search-result-heading');
        searchResultEl.style.top = (retailContainerHeight + document.querySelector('.desktop-nav').clientHeight) + 'px';
      }
    } else {
        $(window).on('resize', () => {
          if (isDesktopWidth()) { // dai-22091: fix must run just on desktop
            $('.retail-banner-mt').css('marginTop', inViewportHeight($('.retail-banner-component')));
          }
        });
    }

    if (exists('.retailbanner-carousel')) {
      $('.retailbanner-carousel').addClass("slide-down");
    } else {
      EventHandler.on('retailbanner:slide-down', () => $(".retailbanner-carousel").addClass("slide-down"));
    }

    if($_PAGE_.is('.homepage')){
      $('.retail-banner-component').css({position: 'static'});
    }
    EventHandler.send('retailbanner:loaded'); // update collapsed booking bar with header plus retail banner margin top
  }

  scrollPageHeaderWhenSmartBannerExists(pageHeaderEl, visibleBannerHeight) {
    const intersectHeightBetweenSmartAndRetailBanner = document.querySelector('#smartbanner').getBoundingClientRect().bottom - document.querySelector('.retail-banner-container').getBoundingClientRect().top;
    if (document.querySelector('.retail-banner-container').getBoundingClientRect().top > 0) {
      pageHeaderEl.style.marginTop = (visibleBannerHeight - intersectHeightBetweenSmartAndRetailBanner) + 'px';
    } else {
      let smartBannerHeight = $('#smartbanner').height();
      if ((visibleBannerHeight - smartBannerHeight) > 0) {
        pageHeaderEl.style.marginTop = (visibleBannerHeight - smartBannerHeight) + 'px';
      } else {
        pageHeaderEl.style.marginTop = '0px';
      }
    }
  }

  monitorScrollAndMoveRetailBanner() {
    document.addEventListener('scroll', (e) => {
      window.requestAnimationFrame(() => {
        let visibleBannerHeight = inViewportHeight($('.retail-banner-container'));
        let pageHeaderEl = ($('#pageHeader').is(':visible')) ? document.querySelector('#pageHeader') : document.querySelector('#pageHeadernew');
        if (window.brand_id === 'wr') {
          document.querySelectorAll('.retail-banner-mt').forEach((el) => {
            el.style.marginTop = visibleBannerHeight + 'px';
          });
          if ($('.page').is('.search-results-page')) {
            let searchResultEl = document.querySelector('.search-result-heading');
            if (isDesktopWidth()) {
              searchResultEl.style.top = (visibleBannerHeight + document.querySelector('.desktop-nav').clientHeight) + 'px';
            } else if (isTabletWidth()) {
              searchResultEl.style.top = (visibleBannerHeight + document.getElementsByClassName('retail-banner-mt')[1].clientHeight) + 'px';
            }
          }
          if ($_PAGE_.is('.homepage-template') && isMobileWidth() && exists('#smartbanner')) {
            const wrPageHeaderEl = document.querySelector('.mobile-nav .top-nav');
            this.scrollPageHeaderWhenSmartBannerExists(wrPageHeaderEl, visibleBannerHeight);
          }
        } else {
          pageHeaderEl.style.marginTop = visibleBannerHeight + 'px';

          if ($('.page').is('.uu-property,.ui-overview')) {
            pageHeaderEl.style.marginTop = $('.retail-banner-container').height() + 'px';
            pageHeaderEl.style.top = '0px';
          }

          if ($('.page').is('.search-results-page'))  {
            let collapsedBookingBarEl = document.querySelector('#bookingBarCollapsed');
            collapsedBookingBarEl.style.top = visibleBannerHeight + pageHeaderEl.clientHeight  + 'px';
          }

          if ($_PAGE_.is('.homepage') && isMobileWidth() && exists('#smartbanner')) {
            this.scrollPageHeaderWhenSmartBannerExists(pageHeaderEl, visibleBannerHeight);
          }

          if ($_PAGE_.is('.search-results-page, .property-page, .rooms-rates-page')) {
            if (isMobileWidth()) {
              $('#pageHeader').css('top', '0px');
            }
            if (isTabletWidth() && $_BOOKING_BAR_MAIN_.is('.open')) {
              const retailBannerPlusPageHeaderHeight = inViewportHeight($('.retail-banner-container')) + $_HEADER_.outerHeight();
              $_BOOKING_BAR_MAIN_.css('top', retailBannerPlusPageHeaderHeight);
            }
          }
        }
      });
    });
  }

  removeRetailBannerPositioningClasses() {
    $('.page').removeClass('has-retail-banner');
    $('.retail-banner-component').remove();
    $_BOOKING_BAR_MAIN_.css({ marginTop: 0 });
    if (brand_id == 'wr') {
      $('.mobile-nav .top-nav, .desktop-nav').removeClass('retail-banner-mt');
    }
  }

  loadSlides(slides, addTopHeader) {
    if (slides.length > 0) {
      this.renderSlidesAndInitialize(slides, addTopHeader);
      this.monitorScrollAndMoveRetailBanner();
    } else {
      this.removeRetailBannerPositioningClasses();
    }
  }
}

export default new Header();


